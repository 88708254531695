.login{
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top:0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 800px;
    //background: #2d3a4b;
    background: #fff;
    .logon{
        width: 100%;
        height:60px;
        display: flex;
        justify-content: center;
        align-items: center;
        >img{
            display: block;
            width:450px;
            height: 73px;
        }
    }
    >.login_box{
        width: 600px;
        min-height:300px;
        border: 1px solid #ccc;
        border-radius: 6px;
        padding: 20px;
        box-sizing: border-box;
        >h4{
            width: 100%;
            text-align: center;
            line-height: 100px;
            font-size: 24px;
            font-weight: 900;
            color: #000;
        }
        .ant-form-item-label > label{
            color: #000;
        }
        .ant-btn{
            width: 400px;
        }
    }
    .ipc{
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 20px;
        text-align: center;
        min-width: 800px;
        >a{
            margin-right: 5px;
        }
    }
}
.setting_box{
    width: 100%;
    background: #ffffff;
    padding: 50px;
    box-sizing: border-box;
}
.li{
  width: 100%;
    display: flex;
    margin-bottom: 10px;
    >b{
        flex: 1;
    }
    >.right{
        flex: 5;
    }
}
.gird{
    width: 100%;
    >.gird_p{
        width: 100%;
        line-height: 20px;
        height: 20px;
        text-align: center;
        font-size: 14px;
        border-bottom: 1px double #cccccc;
        position: relative;

        >.ant-btn{
            position: absolute;
            right: 0;
            top: -8px;

        }
    }
    >h4{
        text-align: center;
        font-weight: 900;
        font-size: 16px;
    }
    .centent{
        text-align: center;
    }
    .left{
        text-align: left;
        text-indent: 24px;
    }
    .ant-row{
        border: 1px solid #000;
        .ant-col{
            border-left: 1px solid #000;
        }
    }
    .title{
        line-height: 36px;
        background: #cef0fa;
        font-size: 14px;
    }
}
.ant-card-body{
    padding: 12px 24px;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
    padding: 10px;
}

.ant-table{
    .ant-btn{
        padding: 0;
    }
}
.noData{
    width: 100%;
    line-height: 200px;
    text-align: center;
    font-size: 16px;
    color: #ccc;
}
.box{
    width: 100%;
    .ul{
        padding: 0;
        border: 1px solid #f5f5f5;
        border-radius: 3px;
        .cursol{
            cursor: pointer;
            //color: #40a9ff;
        }
    }
    .content{
        height: 100%;
        border: 1px solid #f5f5f5;
        border-radius: 3px;
        margin-left: 5px;
        overflow: hidden;
        word-break: break-all;

    }
}
.ant-pagination-item,.ant-pagination-prev{
    margin-bottom: 5px !important;
}
.header{
    text-align: right;
    line-height: 64px;
    color: #fff;
    font-weight: 900;
    font-size: 20px;
    padding-left: 0;
    overflow: hidden;
     >.logo{
         width:450px ;
         height: 100%;
         display: flex;
         align-items: center;
         float: left;
         >img{
             display: block;
             width: 450px ;
             height: 48.5px;
         }
     }
    .authName{
        border-radius: 20px;
        font-size: 14px;
    }
    .out{
        float: right;
        cursor: pointer;
        font-size: 24px;
        font-weight: 100;
        margin-left: 20px;
        margin-top: 22px;
        >svg{
            width: 20px;
            height: 20px;
        }
    }
}
.ant-menu{
    background: #2d3a4b;
}
.layout_box{
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 64px;
    overflow: hidden;
}
.ant-menu-item:hover{
    background: #001529;
    color: #46a6ff !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background: #001529;
    color: #46a6ff !important;
    border-right: 0;
    margin: 0;
}
.ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child){
    margin: 0;
    color: #ffffff;
}
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid #001529;
}
.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title{
    margin: 0;
    color: #ffffff;
}